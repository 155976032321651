import { Component } from "react";
import PillLabel from '../../../../components/PillLabel';
import request from "../../../../request";
import swal from "sweetalert";
import { CircularProgress } from "@mui/material";
import CenteredMessage from "../../../../components/CenteredMessage";


export default class Attendance extends Component {

	state = {
		loading: false,
		attendance: null,
	}

	fetchAttendance = async () => {
		try {
			
			await this.updateState({ loading: false });

			const res = await request.get('/api/general/students/null/attendance');
			const attendance = res.data;

			await this.updateState({ attendance })

		} catch (err) {
			swal(String(err));
		} finally {
			await this.updateState({ loading: false });
		}
	}

	componentDidMount() {
		this.fetchAttendance();
	}

	render() {

		let content;
		const { loading, attendance } = this.state;

		if (loading) {
			content = <div className="py-[70px]">
				<div className="flex justify-center">
					<CircularProgress />
				</div>
			</div>
		} else if (!attendance) {
			content = <div className="h-[200px]">
				<CenteredMessage
					message={"Failed to fetch attendance data"}
					onRefresh={this.fetchAttendance}
				/>
			</div>
		} else {
			content = <div className="flex justify-center">
				<div className="relative">
					<CircularProgress
						size={100}
						variant="determinate"
						value={attendance.average}
					/>
					<div className="absolute top-0 bottom-0 left-0 right-0 text-gray-600 flex items-center justify-center">
						{attendance.average}%
					</div>
				</div>
			</div>
		}

		return <div className="shadow p-4">
			<PillLabel>ATTENDANCE</PillLabel>
			{content}
		</div>
	}
}