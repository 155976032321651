import request from "../request";
import { ADD_CLASS, DELETE_CLASS, SET_AUTHENTICATED, SET_CLASSES, SET_CURRENT_ROUTE, SET_DISPLAY_PUSH_NOTIFICATIONS_OPTIN_DIALOG, SET_IGNORE_PUSH_NOTIFICATIONS_REGISTRATION_STATUS, SET_IS_PWA, SET_ROLE, SET_SUBJECTS, SET_USER, UPDATE_CLASS, UPDATE_USER } from "./reducer/constants";
import store from "./store";


const actions = {  
   setCurrentRoute(route) {
      
      const action = {
         type: SET_CURRENT_ROUTE,
         payload: route,
      };

      store.dispatch(action);
   },

   setRole(role) {
      const action = {
         type: SET_ROLE,
         payload: role,
      };

      store.dispatch(action);
   },

   setAuthenticated(authenticated=true) {
      const action = {
         type: SET_AUTHENTICATED,
         payload: authenticated,
      };

      store.dispatch(action);
   },

   // subject list
   setSubjects(subjects) {
      const action = {
         type: SET_SUBJECTS,
         payload: subjects,
      };

      store.dispatch(action);
   },

   // user profile
   setUser(user) {
      const action = {
         type: SET_USER,
         payload: user,
      }

      store.dispatch(action);
   },


   updateUser(update) {
      const action = {
         type: UPDATE_USER,
         payload: update,
      }

      store.dispatch(action);
   },

   async fetchUser() {
      const res = await request.get('/api/auth/profile');
      return this.setUser(res.data); 
   },

   // classes
   setClasses(classes) {
      const action = {
         type: SET_CLASSES,
         payload: classes,
      }

      store.dispatch(action);
   },

   setIsPWA(isPWA=true) {
      const action = {
         type: SET_IS_PWA,
         payload: isPWA,
      }

      store.dispatch(action);
   },

   updateClass(_id, updates) {
      const action = {
         type: UPDATE_CLASS,
         payload: { _id, updates },
      }

      store.dispatch(action);
   },

   addClass(cls) {
      const action = {
         type: ADD_CLASS,
         payload: cls,
      }

      store.dispatch(action);
   },

   deleteClass(_id) {
      const action = {
         type: DELETE_CLASS,
         payload: _id,
      }

      store.dispatch(action);
   },

   async fetchClasses() {

      const res = await request.get('/api/admin/classes');
      const classes = res.data;

      return this.setClasses(classes);
   },

   // notifications
   setDisplayPushNotificationsOptinDialog(displayPushNotificationsOptInDialog=true) {
      const action = {
         type: SET_DISPLAY_PUSH_NOTIFICATIONS_OPTIN_DIALOG,
         payload: displayPushNotificationsOptInDialog,
      }

      store.dispatch(action);
   },

   setIgnorePushNotificationsRegistrationStatus(ignorePushNotificationsRegistrationStatus=true) {
      const action = {
         type: SET_IGNORE_PUSH_NOTIFICATIONS_REGISTRATION_STATUS,
         payload: ignorePushNotificationsRegistrationStatus,
      }

      store.dispatch(action);
   }

}

export default actions;