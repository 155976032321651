import { Button, Dialog, DialogContent } from "@mui/material";
import { Component } from "react";
import { connect } from "react-redux";
import actions from "../redux/actions";
import firebase from "../firebase";

class PushNotificationsOptinDialogUnconnected extends Component {

	close = () => {
		actions.setDisplayPushNotificationsOptinDialog(false);
	}

	registerForNotifications = () => {
		const { ignoreRegistrationStatus } = this.props;
		firebase.registerForNotifications({ ignoreRegistrationStatus });
		this.close();
	}

	render() {
		return <Dialog open={this.props.open}>
			<DialogContent>
				<div className="w-[400px] max-w-full text-gray-600 p-3 text-center">
					<div className="text-4xl font-bold">Push Notifications</div>
					<div className="text-lg mt-2">
						Allow us to send you important<br/>
						updates via notifications.
					</div>

					<div className="grid grid-cols-2 mt-8">
						<Button fullWidth size="large" variant="contained" onClick={this.registerForNotifications}>
							NOTIFY ME
						</Button>
						<Button fullWidth size="large" onClick={this.close}>
							MAYBE LATER
						</Button>
					</div>
				</div>
			</DialogContent>
		</Dialog>
	}
}

const mapStateToProps = state => ({
	open: state.displayPushNotificationsOptinDialog,
	ignoreRegistrationStatus: state.ignorePushNotificationsRegistrationStatus,
})

const PushNotificationsOptinDialog = connect(mapStateToProps)(PushNotificationsOptinDialogUnconnected);
export default PushNotificationsOptinDialog;

