
import { Button, Divider, IconButton, MenuItem, TextField } from '@mui/material';
import Page from './Page';
import { SCHOOL_TYPES } from '../../../shared/constants';
import { Link } from 'react-router-dom';
import { errorToast, successToast } from '../toast';
import { hideLoading, showLoading } from '../loading';
import request from '../request';
import swal from 'sweetalert';
import CheckIcon from '@mui/icons-material/CheckCircle';
import CopyIcon from '@mui/icons-material/ContentCopy';
import { delay } from '../utils';
import { DEMO_CREDENTIALS_BROADCASTED } from '../constants';
import ArrowRightIcon from '@mui/icons-material/ArrowForward';
import { MetaTags } from 'react-meta-tags';
import MetaTitle from '../components/MetaTitle';
import CanonicalURL from '../components/CanonicalURL';

const STAGES = {
	CREATING: 'creating',
	CREATED: 'created'
}

export default class Demo extends Page {

	state = {
		type: '',
		stage: STAGES.CREATING,
		email: '',
		password: '',
	}

	submit = async () => {

		// presence check
		const txtName = document.getElementById('txt-name');
		const txtType = document.getElementById('txt-type');

		const name = txtName.value;
		const type = this.state.type;

		if (!name) {
			errorToast('Provide school name');
			return txtName.focus();
		}

		if (!type) {
			errorToast('Select school type');
			return txtType.focus();
		}

		try {

			showLoading();

			const res = await request.post('/api/general/demo-accounts', { name, type });
			const { email, password } = res.data;

			this.setState({
				email,
				password,
				stage: STAGES.CREATED,
			})

		} catch (err) {
			swal(String(err));
		} finally {
			hideLoading();
		}
	}

	_render() {

		let jsx;

		if (this.state.stage === STAGES.CREATING) {
			jsx = <div className='px-8 [&>*]:my-8'>
				<div className='grid grid-cols-2 gap-4'>
					<TextField
						id='txt-name'
						label='School name'
						size='small'
						variant='outlined'
						fullWidth
					/>
					<TextField
						id='txt-type'
						label='Type'
						size='small'
						variant='outlined'
						fullWidth
						select
						value={this.state.type}
						onChange={e => this.setState({ type: e.target.value })}
						className='capitalize'
					>
						{
							Object
								.values(SCHOOL_TYPES)
								.map(type => {
									return <MenuItem value={type} key={type} className='capitalize'>
										{type.replaceAll('-', ' ')}
									</MenuItem>
								})

						}
					</TextField>

				</div>

				<div>
					<Button variant='contained' fullWidth size='large' className='rounded-full' onClick={this.submit}>
						CREATE ACCOUNT
					</Button>

					<div className='grid grid-cols-[1fr,auto,1fr] gap-4 mt-2'>
						<div className='text-right'>
							<Button component={Link} size='large' to="/login">
								LOGIN
							</Button>
						</div>
						<div className='flex items-center'>
							<div className='bg-gray-400 h-[50%] w-[2px]' />
						</div>
						<div>
							<Button component={Link} size='large' to="/signup">
								SIGN UP
							</Button>
						</div>
					</div>
				</div>
			</div>
		} else {
			jsx = <div className='px-8 py-2'>
				<div className='grid grid-cols-[auto,1fr]'>
					<div className='text-green-600 flex items-center'>
						<CheckIcon fontSize="large" />
					</div>
					<div>
						<div className='text-gray-600 text-lg font-bold'>
							Demo account successfully created
						</div>
						<div className='text-gray-600 text-sm'>
							It will expire <b>24 hours</b> from now
						</div>
					</div>
				</div>

				<div className='[&>*]:mt-2 mt-4'>
					<TextItem text={this.state.email} />
					<TextItem text={this.state.password} obscure />
				</div>

				<Divider className='mt-4 mb-2' />

				<span className='text-gray-600 text-sm'>
					Use the credentials above for login. Copy them before leaving this page
				</span>

				<Divider className='mt-3' />

				<div className='text-right'>
					<Button
						endIcon={<ArrowRightIcon />}
						onClick={async () => {
							// go to login page
							window.App.redirect('/login');
							await delay(300);

							// emit event
							const { email, password } = this.state;
							const event = new CustomEvent(DEMO_CREDENTIALS_BROADCASTED, { detail: { email, password }});
							
							window.dispatchEvent(event);
							document.dispatchEvent(event);
							
						}}
					>
						LOGIN
					</Button>
				
				</div>
				
			</div>
		}

		return <>

			<MetaTags>
            <MetaTitle>Create Demo Account</MetaTitle>
            <meta name="description" content="Create a demo account to have an appreciation of how the system work." />
				<CanonicalURL />
          </MetaTags>
			<div className='h-full flex items-center justify-center bg-gray-100'>
				<div className='bg-white shadow rounded-2xl mx-4 w-full max-w-[500px]'>
					<div className='text-3xl font-bold text-gray-600 text-center my-4'>Demo Account</div>
					<Divider />
					{jsx}
				</div>
			</div>
		</>
	}
}

function TextItem({ text, obscure=false }) {

	return <div className='bg-gray-200 grid grid-cols-[1fr,auto] px-2 rounded'>
		<div className='flex items-center'>
			<div className='text-truncate text-gray-600 font-semibold'>
				{obscure ? '********' : text}
			</div>
		</div>
		<div>
			<IconButton
				onClick={async () => {
					await navigator.clipboard.writeText(text);
					successToast('Copied!');
				}}
			>
				<CopyIcon />
			</IconButton>
		</div>
	</div>
}