import { Avatar, Button, Chip, Divider, Popover, TextField } from "@mui/material";
import Page from "../Page";
import LoginIcon from '@mui/icons-material/LockOpen';
import { errorToast } from "../../toast";
import swal from 'sweetalert';
import request from "../../request";
import { hideLoading, showLoading } from "../../loading";
import { Link } from "react-router-dom";
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import EmailIcon from '@mui/icons-material/AlternateEmail';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import actions from "../../redux/actions";
import { decodeJWT } from "../../utils";
import { getAccessToken } from "@xavisoft/auth/frontend/utils";
import { DEMO_CREDENTIALS_BROADCASTED, ENTER_KEY_PRESSED } from "../../constants";
import { MetaTags } from "react-meta-tags";
import MetaTitle from "../../components/MetaTitle";
import CanonicalURL from "../../components/CanonicalURL";
import ResetPassword from "./ResetPassword";
import { parseEmailOrPhone } from "./utils";
import firebase from "../../firebase";

const STAGES = {
   EMAIL_OR_PHONE: 'EMAIL_OR_PHONE',
   ROLE: 'ROLE',
   SCHOOL: 'SCHOOL',
   PASSWORD: 'PASSWORD',
}

function getStageIndex(stage) {
   const stages = Object.values(STAGES);
   return stages.indexOf(stage);
}

function getStageByIndex(index) {
   const stages = Object.values(STAGES);
   return stages[index];
}

export default class Login extends Page {

   state = {
      stage: STAGES.EMAIL_OR_PHONE,
      email_or_phone: '',
      password: '',
      roles: [],
      schools: [],
      role: '',
      school: undefined,
      showEnteredDataPopover: false,
      showResetPasswordForm: false,
   }

   next = async () => {
      const { stage } = this.state;

      switch (stage) {
         case STAGES.EMAIL_OR_PHONE:
            
            const { email_or_phone } = this.state;

            if (!email_or_phone) {
               errorToast("Enter your email or phone");
               return document.getElementById('txt-email-or-phone').focus();
            }

            try {

               showLoading();

               const query = new URLSearchParams(parseEmailOrPhone(email_or_phone)).toString();
               const res = await request.get(`/api/auth/roles?${query}`, { email_or_phone });

               const stateUpdate = {
                  roles: res.data.roles
               }

               stateUpdate.stage = res.data.roles.length > 0 ? STAGES.ROLE : STAGES.PASSWORD;

               this.setState(stateUpdate);

            } catch (err) {
               swal(err.message);
               return;
            } finally {
               hideLoading();
            }

            break;

         case STAGES.ROLE:
            
            const { role } = this.state;

            if (role === null) {
               this.setState({ stage: STAGES.PASSWORD });
               return;
            }

            if (!role) {
               errorToast('Select role');
               return;
            }

            const schools = this.state.roles.find(item => item.role === role).schools;
            const stateUpdate = { schools };

            if (schools && schools.length > 0)
               stateUpdate.stage = STAGES.SCHOOL;
            else
               stateUpdate.stage = STAGES.PASSWORD;

            this.setState(stateUpdate);

            break;

         case STAGES.SCHOOL:

            const { school } = this.state;
            if (!school) {
               errorToast('Select school');
               return;
            }

            this.setState({ stage: STAGES.PASSWORD });
            break;

         case STAGES.PASSWORD:
            const txtPassword = document.getElementById('txt-password');
            const password = this.state.password;

            if (!password) {
               errorToast("Provide your password");
               return txtPassword.focus();
            }

            try {

               showLoading();
               
               const { role, school } = this.state;
               const { email, phone } = parseEmailOrPhone(this.state.email_or_phone);

               const data  = { 
                  email,
                  phone,
                  password,
                  role: role || undefined,
                  school: school?._id
               }

               await request.post('/api/auth/login', data);

               this.setAuthenticatedAndGoToDashboard(role, true);

            } catch (err) {
               swal(err.message);
            } finally {
               hideLoading();
            }
            break;

         default:
            break;
      }
   }

   back = () => {
      const { stage } = this.state;
      const currentStageIndex = getStageIndex(stage);

      if (currentStageIndex === 0)
         return;

      const newStage = getStageByIndex(currentStageIndex - 1);
      this.setState({ stage: newStage });
   }

   renderEmailOrPhone() {
      return <div className="my-4">
         <TextField
            id="txt-email-or-phone"
            label="Email or phone"
            variant="outlined"
            size="small"
            value={this.state.email_or_phone}
            onChange={e => this.updateState({ email_or_phone: e.target.value })}
            fullWidth
         />

         <p className="text-gray-500 text-sm mt-4">
            Make sure the email or phone you provided is verified
         </p>

      </div>
   }

   renderRole() {

      const roles = [
         ...this.state.roles,
         {
            role: null,
            schools: []
         }
      ]

      return <div className="my-4">
         {
            roles.map(item => {

               const { role } = item;
               const icon = role === this.state.role ? <RadioButtonCheckedIcon color="primary" /> : <RadioButtonUncheckedIcon className="text-gray-500" />;

               return <div
                  className="grid grid-cols-[auto,1fr] gap-2 my-2 cursor-pointer"
                  onClick={
                     () => this.setState({ role })
                  }
               >
                  <div>
                     {icon}
                  </div>
                  <div className="capitalize text-gray-500">
                     {role ? role.replaceAll('_', ' ') : '(no role)'}
                  </div>
               </div>
            })
         }

      </div>;
   }

   renderSchool() {

      return <div className="my-4">

         {
            this.state.schools.map(school => {

               const { _id, name, address } = school;
               const icon = _id === this.state.school?._id ? <RadioButtonCheckedIcon color="primary" /> : <RadioButtonUncheckedIcon className="text-gray-500" />;

               return <div
                  className="grid grid-cols-[auto,1fr] gap-2 my-2 cursor-pointer"
                  onClick={
                     () => this.setState({ school })
                  }
               >
                  <div>
                     {icon}
                  </div>
                  <div>
                     <div className="capitalize">
                        {name}
                     </div>
                     <div className="text-gray-500">
                        {address}
                     </div>
                  </div>
               </div>
            })
         }

      </div>
   }

   renderPassword() {
      return <div className="my-4">
         <TextField
            id="txt-password"
            label="Password"
            variant="outlined"
            size="small"
            type="password"
            fullWidth
            value={this.state.password}
            onChange={e => this.setState({ password: e.target.value })}
         />
      </div>
   }

   renderEnteredData() {

      if (this.state.stage === STAGES.EMAIL_OR_PHONE)
         return;

      let emailOrPhoneChip;

      const { email_or_phone } = this.state

      if (email_or_phone) {
         emailOrPhoneChip = <Chip
            label={email_or_phone}
            color="secondary"
            className="text-xs"
            icon={<EmailIcon />}
            size="small"
            deleteIcon={<ExpandMoreIcon />}
            onDelete={
               () => {
                  this.setState({ showEnteredDataPopover: true })
               }
            }
         />
      }

      return <span id="span-entered-data">
         {emailOrPhoneChip}
         {this.renderEnteredDataPopover()}
      </span>;
   }

   renderEnteredDataPopover() {
      if (!this.state.showEnteredDataPopover)
         return;

      const anchor = document.getElementById('span-entered-data');
      if (!anchor)
         return;

      const { email_or_phone } = this.state;
      const { email, phone } = parseEmailOrPhone(email_or_phone);
      const emailOrPhoneLabel = email ? 'Email' : 'Phone';

      const rows = [{
         label: emailOrPhoneLabel,
         data: email || phone,
      }];

      const currentStageIndex = getStageIndex(this.state.stage);

      if (currentStageIndex > getStageIndex(STAGES.ROLE)) {
         rows.push({
            label: 'Role',
            data: this.state.role.replaceAll('_', ' ') || '(no role)'
         });
      }

      if (currentStageIndex > getStageIndex(STAGES.SCHOOL) && this.state.school) {
         rows.push({
            label: 'School',
            data: this.state.school.name
         });
      }

      return <Popover 
         anchorEl={anchor} 
         open
         anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
         }}
         onClose={() => {
            this.setState({ showEnteredDataPopover: false })
         }}
      >
         <div className="p-4 grid grid-cols-[auto,1fr] gap-2 text-xs">
            {
               rows.map(row => {
                  return <>
                     <div className="text-gray-500">
                        {row.label}
                     </div>
                     <div>
                        {row.data}
                     </div>
                  </>
               })
            }
         </div>
      </Popover>
   }

   setAuthenticatedAndGoToDashboard(role=null, ignorePushNotificationsRegistrationStatus=true) {
      actions.setRole(role);
      actions.setAuthenticated(true);
      window.App.redirect('/dashboard');

      // push notifications
      const { registered } = firebase.getFCMLocalStorage();

      if (!registered || ignorePushNotificationsRegistrationStatus) {
         actions.setDisplayPushNotificationsOptinDialog(true);
         actions.setIgnorePushNotificationsRegistrationStatus(ignorePushNotificationsRegistrationStatus);
      }
   }

   checkLoginStatus() {
      const accessToken = getAccessToken();
      if (!accessToken)
         return;

      const decoded = decodeJWT(accessToken);

      if (decoded.exp <= Date.now())
         return;

      const role = decoded?.user?.role;
      this.setAuthenticatedAndGoToDashboard(role, false);
   }

   componentWillUnmount() {
      window.removeEventListener(ENTER_KEY_PRESSED, this.next);
   }

   componentDidMount() {
      super.componentDidMount();

      // auth stuff
      actions.setAuthenticated(false);
      this.checkLoginStatus();

      // listen to enter
      window.addEventListener(ENTER_KEY_PRESSED, this.next);

      // focus on email or phone
      document.getElementById('txt-email-or-phone').focus();

      // listen for demo credentials
      window.addEventListener(DEMO_CREDENTIALS_BROADCASTED, e => {
         const { email, phone, password } = e.detail;
         const email_or_phone = email || phone;
         this.setState({ email_or_phone, password });
      })
   }

   _render() {

      let form, subtitle;

      switch (this.state.stage) {
         case STAGES.EMAIL_OR_PHONE:
            subtitle = "Provide your email or phone";
            form = this.renderEmailOrPhone();
            break;
         case STAGES.ROLE:
            subtitle = "Select the role you want to login as"
            form = this.renderRole();
            break;
         case STAGES.SCHOOL:
            subtitle = "Select the school you want to access";
            form = this.renderSchool();
            break;
         case STAGES.PASSWORD:
            subtitle = "Provide your password";
            form = this.renderPassword();
            break;
         default:
            form = 'Nothing';
      }

      let leftButton;
      if (this.state.stage === STAGES.EMAIL_OR_PHONE) {
         leftButton = <Button component={Link} to="/signup">
            Signup
         </Button>
      } else {
         leftButton = <Button onClick={this.back}>
            BACK
         </Button>
      }

      const enteredData = this.renderEnteredData();

      let forgotPassword;

      if ([ STAGES.EMAIL_OR_PHONE, STAGES.PASSWORD ].includes(this.state.stage)) {
         forgotPassword = <>
            <Button
               fullWidth
               size="small"
               className="capitalize underline underline-offset-[5px] mt-4"
               onClick={() => this.setState({ showResetPasswordForm: true })}
            >
               Forgot password?
            </Button>
            {
               this.state.showResetPasswordForm && <ResetPassword
                  close={() => this.setState({ showResetPasswordForm: false })}
                  username={this.state.email_or_phone}
               />
            }
         </>   
      }

      return <>
         <MetaTags>
            <MetaTitle>Login</MetaTitle>
            <meta name="description" content="Login to access your account" />
            <CanonicalURL />
         </MetaTags>
         <div className="h-full flex items-center justify-center">
            <div>
               <div className="w-full md:w-[300px] p-[30px] border-none md:border-solid border-[1px] border-grey-400 rounded">
                  <div className="flex justify-center">
                     <div>
                        <div className="flex justify-center">
                           <Avatar className="bg-[var(--primary)]">
                              <LoginIcon />
                           </Avatar>
                        </div>

                        <h1 className="flex justify-center text-lg mt-2 font-bold text-gray-500">
                           Login to your account
                        </h1>

                        <div className="flex justify-center my-2">
                           {enteredData}
                        </div>

                        <p className="text-gray-400 text-xs text-center">
                           {subtitle}
                        </p>

                     </div>
                  </div>

                  <div>

                     {form}

                     <Divider className="my-4" />

                     <div className="grid grid-cols-2 gap-4">
                        <div>
                           {leftButton}
                        </div>
                        <div className="flex justify-end">
                           <Button variant="contained" onClick={this.next}>
                              NEXT
                           </Button>
                        </div>
                     </div>

                  </div>
               </div>
               {forgotPassword}
            </div>
         </div>
      </>
   }
}