import { Button, Container, Typography } from "@mui/material";
import Page from "./Page";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Link } from "react-router-dom";
import { MetaTags } from "react-meta-tags";
import CanonicalURL from "../components/CanonicalURL";


export default class Home extends Page {
   _render() {
      return <>
         <MetaTags>
            <title>Classmate – A school management system from Xavisoft Digital</title>
            <meta name="description" content="Classmate is a platform that connects students, teachers, and parents. It is a one-stop solution for all stakeholders in the education system. Simplifies school management, boosts parental involvement." />
            <CanonicalURL />
         </MetaTags>

         <div className="h-full flex items-center bg-[#00F1]">
            <div className="w-full">
               <Container>
                  <div className="grid md:grid-cols-2 gap-3">
                     <div className="flex h-full items-center">
                        <div>
                           <div>
                              <Typography
                                 color={"primary"}
                                 className="font-bold text-5xl"
                              >
                                 One app for
                              </Typography>
                           </div>
                           <div>
                              <Typography
                                 color={"secondary"}
                                 className="font-bold text-5xl"
                              >
                                 all stakeholders
                              </Typography>
                           </div>

                           <p className="text-gray-600 my-4">
                              Classmate is a platform that connects students, teachers, and parents. It is a one-stop solution for all stakeholders in the education system. Simplifies school management, boosts parental involvement.
                           </p>

                           <div className="grid sm:inline-grid grid-cols-1 sm:grid-cols-2 gap-4">
                              <Button
                                 component={Link}
                                 to="/demo"
                                 variant="contained"
                                 size="large"
                                 fullWidth
                              >
                                 DEMO ACCOUNT
                              </Button>
                              <Button
                                 endIcon={<ArrowForwardIcon />}
                                 component={Link}
                                 to="/signup"
                                 variant="outlined"
                                 size="large"
                                 fullWidth
                              >
                                 GET STARTED
                              </Button>
                           </div>
                        </div>
                     </div>
                     <div className="hidden md:flex h-full items-center justify-center">
                        <div
                           className="w-96 h-96 bg-gradient-to-br from-[#1976D2] to-[#00F2] rounded-3xl shadow-xl rotate-[70deg]"
                        />
                     </div>
                  </div>
               </Container>
            </div>
         </div>
      </>
   }
}